*{
    margin:0;
    padding:0;
   
}

:root{
    --scroll-content-width: 100vw;
    --scroll-content-height: 4vh;
    --scroll-elements: 53;
    --scroll-elements-displayed: 3;
    --scroll-element-width: calc(var(--scroll-content-width)/var(--scroll-elements-displayed));
    --scroll-animation-duration:calc(var(--scroll-elements)*1.5s);
}

.scroll-container
{
    border-top: 2px solid #F68C1D ;
    border-bottom: 2px solid #F68C1D;
    align-items: center;
    background-color:#d8d8d8;
    background-color:#d8d8d8;
    width:var(--scroll-content-width);
    height:var(--scroll-content-height);
    overflow: hidden;
    position: fixed;
    left:0;
    z-index: 1;
    
   
}

.scroll-content
{
    list-style:none;
    background-color:#d8d8d8;
    
    height:100%;
    display:flex;
    animation: scrolling var(--scroll-animation-duration) linear infinite
}
@keyframes scrolling {
    0%{
        transform:translateX(0);
    }
    100%{
        transform:translateX(calc(-1*var(--scroll-element-width)*var(--scroll-elements)));
    }
}
.scroll-content li
{
    display:flex;
    justify-content: space-around;
    background-color:#d8d8d8;
    width:var(--scroll-element-width);
    flex-shrink: 0;
    font-size:3;
    white-space: nowrap;


}




/* @media (max-width:600px)
{
    html {font-size:12px;}
    :root{
        --marquee-width:100vw;
        --marquee-height:16vh;
        --marquee-elements-displayed:4;
    }
} */



/*.scroll-container
{
    background-color: lightgray;
    height:5vh;
    width:100vw;
    overflow:hidden;
}


.scroll-text-div
{
   
    color: #000;
    font-size: 2em;
    background-color: blue;
    font-weight: 900;
    text-transform: uppercase;
    animation: animate 5s linear infinite;    
}

@keyframes animate
{
    0%
    {
        transform:translateX(100%);
    }
    100%
    {
        transform:translateX(-100%);
    }
}
*/