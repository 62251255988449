@import '~antd/dist/antd.css';

.footer-menu{
  justify-content: 'space-between';
}

/* .Portfolio-Column{
  background-color: #141414;
  color:#F68C1D;
  border:1px #F68C1D ;

} */
.Portfolio-Column td,  .Portfolio-Column tr, .Portfolio-Column th {
 
  background-color: #141414;
  color:#F68C1D;
  border:1.5px solid #F68C1D ;
  
}
.Header{
  background-color: #141414;
  color:#F68C1D;
  
}
.Content{
background-color: #141414;
margin-bottom:10vh;
max-zoom: 0;
user-zoom: 0;
position:sticky;
}

.DropDownStyle{
  background-color: #141414;
  color:#F68C1D;
  

}

.App {
  text-align: center;
  max-zoom: 0;
  min-zoom: 0;
  position:absolute;
  left:0;
  right:0;
}
.tab-list-active
{
  color:'#F68C1D';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
.Logo-Animation
{
animation: App-logo-run 1 1.1s linear;

}
}

@keyframes App-logo-run {
  from {
   transform: translateY(0px);
   /*transform: rotate(0deg);*/
     
  }
  to {
    transform: translateY(-350px);
    /*transform: rotate(360deg);*/

  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
