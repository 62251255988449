.tournament-tile-container{
    width: 390px;
    height: 130px;
    background-color: black;
    border-radius: 10px;
    border: 3px solid #F68C1D;
    margin: 10px auto;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 70% 30%;
    overflow: hidden;
    box-shadow: 0px 5px 10px rgba(0,0,0,.1);
   }
   
   @media screen and (max-width:400px) {
       .tournament-tile-container{   
            width: 350px;
            grid-template-columns: 25% 75%;
       }
   }
   .tournament-img{
       width: 100%;
       height: 100%;
       grid-row: 1/3;
       grid-column: 1/2;
   
   }
   
   .tournament-block--2{
       grid-row: 1/2;
       grid-column: 2/3;
       background-color: #F8F8F8;
   
       display: grid;
       grid-template-columns: 70% 30%;
       border-left: 3px solid #F68C1D;
       text-align: center;
   }
   
   .tournament-block--3{
       grid-row: 2/3;
       grid-column: 2/3;
       background-color:#F8F8F8;
   
       display: grid;
       grid-template-columns: 40% 25% 35%;
       border-left: 3px solid #F68C1D;
       border-top: 3px solid #F68C1D;
   }
   .tournament-countdown{
       display: flex;
       justify-content: space-evenly;
   }
   
   .tournament-text{
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: center;
   }
   
   .tournament-info{
      padding-top: 3px;
      padding-left: 3px;
   
   }
   .ant-statistic-content{
      line-height: 30px;
   }
   .ant-statistic-content-value{
       font-size: 14px;
   }
   
   body {
       background: #444;
     }
     
     .playButton {
       position: relative;
       display: block;
       width: 50px;
       height: 50px;
       border-radius: 50%;
       background: radial-gradient( rgba(246, 140, 29, 0.8) 60%, rgba(255, 255, 255, 1) 62%);
       box-shadow: 0px 5px 10px rgba(0,0,0,.1);
       margin:17px auto;
       cursor: pointer;
     }
     
     /* triangle */
     .playButton::after {
       content: "";
       left: 50%;
       top: 50%;
       position: absolute;
       width: 0;
       height: 0;
       border-top: 15px solid transparent;
       border-bottom: 15px solid transparent;
       border-left: 25px solid #fff;
       z-index: 20;
       transform-origin: center center;
       transform: translateX(-40%) translateY(-50%);
       transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
     }
     